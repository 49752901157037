import { useEffect } from 'react';
import './ChatType.css'
import { useNavigate } from 'react-router-dom';
import { useShareWindow } from "../../context/ShareWindowContext.js";
import build_profile from "../../img/chat/build_profile.svg";
import chat_with_aspira from "../../img/chat/chat_with_aspira.svg";
import optiontest from "../../img/options/symptom-checker.png";



const ChatType = () => {
  const navigate = useNavigate();
  const { selectedType, setSelectedType } = useShareWindow();

 
  
  return (
    <div className="chat-type-container">
      <div className="chat-type-inner">
        <div className="chat-type-items">
          <div className="chat-type-item-wrapper">
            <div className="chat-type-item" onClick={() =>   setSelectedType('type1')}>
              <div className='chat-type-item-img'>
                <img src={chat_with_aspira} alt="" />
              </div>
              <p>Chat with Aspira</p>
            </div>
          </div>
          <div className="chat-type-item-wrapper">
            <div className="chat-type-item" onClick={() => window.location.href = '/estimation'}
            >
              <div className='chat-type-item-img'>
                <img src={build_profile} alt="" />
              </div>
              <p>Get Quatetion</p>
            </div>
          </div>
        </div>
        {/* <div className="option-wrapper">
              <div className="option-container">
                <div className="options options1">
                
                    <button className="options-item" onClick={() =>   setSelectedType('type1')}>
                      <img src={optiontest} alt="optiontest" />
                      <div className="option-text">
                        <p>Ask me anything</p>
                      </div>
                    </button>
                    <button className="options-item" onClick={() =>   setSelectedType('type2')}>
                      <img src={optiontest} alt="optiontest" />
                      <div className="option-text">
                        <p>Aspira Uni guide</p>
                      </div>
                    </button>
                    <button className="options-item">
                      <img src={optiontest} alt="optiontest" onClick={() => window.location.href = '/career-predictor'}/>
                      <div className="option-text">
                        <p>Career Predictor</p>
                      </div>
                    </button>
                    <button className="options-item">
                      <img src={optiontest} alt="optiontest" onClick={() => window.location.href = '/profile-builder'}/>
                      <div className="option-text">
                        <p>Profile Builder</p>
                      </div>
                    </button>
                
                </div>
                <div className="options options2" >
                <button className="options-item" onClick={() => window.location.href = '/personality-test'}>
                      <img src={optiontest} alt="optiontest" />
                      <div className="option-text">
                        <p>Personality Test</p>
                      </div>
                    </button>
                    <button className="options-item" onClick={() => window.location.href = '/smart-student'}>
                      <img src={optiontest} alt="optiontest"  />
                      <div className="option-text">
                        <p>Smart Student</p>
                      </div>
                    </button>
                    <button className="options-item">
                      <img src={optiontest} alt="optiontest" />
                      <div className="option-text">
                        <p>option test</p>
                      </div>
                    </button>
                    <button className="options-item">
                      <img src={optiontest} alt="optiontest" />
                      <div className="option-text">
                        <p>option test</p>
                      </div>
                    </button>
                </div>
              </div>
            </div> */}
      </div>
    </div>
  )
}

export default ChatType
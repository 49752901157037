import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import userLogo from "../../img/user_profile.png";
import apirations_ilab_logo from "../../img/logo/apirations_ilab_logo.png";
import "./ShareComponent.css";
import user from "../../img/Ilab_Profile.jpg";
import { useShareWindow } from "../../context/ShareWindowContext.js";
import PlayLoading from "../../img/chat/playloading.js";
import stop from "../../img/chat/stop_button.png";
import play from "../../img/chat/listen_button.png";
import aspi_desktop_idle from "../../videos/chat/aspi_desktop_idle.mp4";
import aspi_desktop_lip_synced from "../../videos/chat/aspi_desktop_lip_synced.mp4";
import aspi_desktop_thumbnail from "../../img/chat/aspi_desktop_thumbnail.jpg";
import aspi_mobile_idle from "../../videos/chat/aspi_mobile_idle.mp4";
import aspi_mobile_lip_synced from "../../videos/chat/aspi_mobile_lip_synced.mp4";
import aspi_mobile_thumbnail from "../../img/chat/aspi_mobile_thumbnail.jpg";



const AUDIO_SYNTHESIS_API_URL =
  "https://ask.aspirations.solutions:5000/api/convert-text-to-speech";
const SHARE = "https://dashboard.knightingale.ai/ai/conversation-history";

const ShareComponent = () => {
  const { session } = useParams();
  const [conversationHistory, setConversationHistory] = useState([]);
  const { currentlyPlayingIndex, setCurrentlyPlayingIndex, isVoiceSynthesisInProgress, setIsVoiceSynthesisInProgress,
    setIsAutoPlayInProgress, setIsAudioPlaying, handleStopButtonClick,
    audioData, setAudioData, playAudio, textResponse, userHistory, setShowDropdown, audioVideoChange
  } = useShareWindow();
  const axiosInstance = axios.create();


  useEffect(() => {
    const fetchConversationHistory = async () => {
      try {
        const response = await axios.get(SHARE + `/${session}`);
     
        setConversationHistory(response.data.conversations);
        console.log(response.data.conversations)
      } catch (error) {
        console.error("Error fetching conversation history:", error);
      }
    };

    fetchConversationHistory();
  }, [session]);

  const triggerVoiceSynthesis = async (textResponse, isAutoPlay) => {
    try {
      setIsVoiceSynthesisInProgress(true);

      const audioRes = await axiosInstance.post(AUDIO_SYNTHESIS_API_URL, {
        text: textResponse,
      });

      // Save the audio URL for later playback along with the corresponding text
      const audioUrl = audioRes.data.audioUrl;
      setAudioData((prevAudioData) => ({
        ...prevAudioData,
        [textResponse]: audioUrl,
      }));

      setIsVoiceSynthesisInProgress(false);
    } catch (error) {
      console.error(error);
      setIsVoiceSynthesisInProgress(false);
    }
  };


  useEffect(() => {
    // adjustTextareaHeight();
    window.addEventListener("click", handleWindowClick);

    const playResponseVoice = async () => {
      try {
        if (textResponse) {
          // Play the audio URL if it exists
          if (audioData[textResponse]) {

          } else {
            await triggerVoiceSynthesis(textResponse, true);
            setIsAudioPlaying(true);
            const currentlyPlayingAudio = userHistory.length;
            setCurrentlyPlayingIndex(currentlyPlayingAudio);
            setIsAutoPlayInProgress(false); // Reset auto-play in progress
            setIsAudioPlaying(false);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    playResponseVoice();

    return () => {
      window.removeEventListener("click", handleWindowClick);
    };
  }, [textResponse, audioData, userHistory]);


  const handleWindowClick = () => {
    setShowDropdown(false);
  };
  return (
    <div className="share-wrapper">
      <div className="title-container">
        <div className="rosapark-logo-wrapper">
          <a href="https://ask.aspirationsilab.com/" >
            <img src={apirations_ilab_logo} alt="Share Icon" className="share-icon" />
          </a>
        </div>
      </div>
      <div className="share-middle-container-wrapper">
        <div className="share-container">


          <div className="share_middle_wrapper">
            <div className="share_container">
              <div className="share_knightigale_wrapper">

                <div className="desk_home_video_wrapper_share " style={{ width: '100%' }}>
                  <div className="desk_home_video_container_share">
                    <div className="new-background-video-container-chat videoclass start_videoclass">
                      <div
                        style={{
                          position: `${audioVideoChange ? 'static' : 'absolute'}`,
                          width: '100%'

                        }}
                        className="share_video"

                      >

                        <video
                          className={`new-background-video-container-chat-video desktop-container-chat-video`}
                          style={{
                            // opacity: `${audioVideoChange ? '0' : '1'}`, left: '-55%',
                            // zIndex: '-10',
                            // position: `${audioVideoChange ? 'relative' : 'static'} `
                          }}
                          playsInline
                          autoPlay
                          loop
                          muted
                          poster={aspi_desktop_thumbnail}
                          src={aspi_desktop_idle}
                        />

                        <video
                          className={`new-background-video-container-chat-video mobile-container-chat-video`}
                          style={{
                            // opacity: `${audioVideoChange ? '0' : '1'}`, left: '-55%',
                            // zIndex: '-10',
                            // position: `${audioVideoChange ? 'relative' : 'static'} `
                          }}
                          playsInline
                          autoPlay
                          loop
                          muted
                          poster={aspi_mobile_thumbnail}
                          src={aspi_mobile_idle}
                        />
                      </div>
                      <div
                        style={{
                          position: `${audioVideoChange ? 'absolute' : 'static'}`,
                          opacity: `${audioVideoChange ? '1' : '0'}`,
                          transition: 'opacity 200ms ease',
                          width: '100%'
                        }}
                        className="share_video"
                      >

                        <video
                          className="new-background-video-container-chat-video desktop-container-chat-video"
                          style={{
                            // left: '-65%',
                            // zIndex: '-10',
                            // position: `${audioVideoChange ? 'relative' : 'static'} `, opacity: `${audioVideoChange ? '1' : '0'} `
                          }
                          }
                          laysInline
                          autoPlay
                          loop
                          muted
                          poster={aspi_desktop_thumbnail}
                          src={aspi_desktop_lip_synced}
                        />

                        <video
                          className="new-background-video-container-chat-video mobile-container-chat-video"
                          style={{
                            // left: '-65%',
                            // zIndex: '-10',
                            // position: `${audioVideoChange ? 'relative' : 'static'} `, opacity: `${audioVideoChange ? '1' : '0'} `
                          }
                          }
                          laysInline
                          autoPlay
                          loop
                          muted
                          poster={aspi_mobile_thumbnail}
                          src={aspi_mobile_lip_synced}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="share_knightingale_conversation">
                <div style={{ height: '70px' }} className="conversation-balance-top">
                </div>
                <ul className="conversation-list">
                  <div className="types_wrappper">
                    <div className="types_container">
                      <div className="types_details">
                        <div className="types_details_title">Explore how Aspi from Aspirations I-Lab can help turn your ideas into reality. Check out this chat!</div>
                        <div className="types_details_date">
                          {/* {conversationHistory.length > 0 && (
                            <p className="date">
                              {" "}
                              {new Date(conversationHistory[0].timestamp).toLocaleDateString()}
                            </p>
                          )} */}
                        </div>
                      </div>
                      <div className="types_option">
                        <div className="type_option_container">
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* {conversationHistory.map((item, index) => (
                    <li key={index} className="message-container">
                      <div className="user-message-container">
                        <div className="user-message">
                          <div className="user-message-details">
                            <img src={userLogo} alt="User Logo" className="message-icon" />
                            <span className="title">User</span>
                          </div>
                          <p className="response client-question">{item.user_input}</p>
                        </div>
                      </div>
                      <div className="knightingale-message-container">
                        <div className="knightingale-message">
                          <div className="knightingale-message-details">
                            <div className="aspira-bot-profile">
                              <img
                                src={user}
                                alt="Knightingale Logo"
                                className="message-icon"
                              />
                            <div className="title">Aspira</div>
                            </div>
                          </div>

                          <div className="response bot-answer">
                            <div className="play-button-container">
                              {isVoiceSynthesisInProgress && (
                                <div className="play_loading_wrapper">
                                  <div className="play_loading_container">
                                    <PlayLoading />
                                  </div>
                                </div>
                              )}
                              {!isVoiceSynthesisInProgress && // Check if audio synthesis is not in progress
                                (currentlyPlayingIndex === index ? (
                                  <button
                                    type="button"
                                    onClick={handleStopButtonClick}
                                    className={`stop-button ${item.response ? "" : "hidden"
                                      }`}
                                  >
                                    <img
                                      src={stop}
                                      height={20}
                                      width={20}
                                      alt="Knightingale said"
                                      className="stop-icon"
                                    />
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    onClick={() => playAudio(item.response, index)}
                                    className={`play-audio-button ${item.response ? "" : "hidden"
                                      }`}
                                  >
                                    {currentlyPlayingIndex !== index ? (
                                      <img
                                        src={play}
                                        height={30}
                                        width={30}
                                        alt="Knightingale says"
                                        className="play-icon"
                                      />
                                    ) : null}
                                  </button>
                                ))}
                            </div>
                            <div>
                              {item.response}
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))} */}
                  {conversationHistory.map((item, index) => (
  <li key={index} className="message-container">
    <div className="user-message-container">
      <div className="user-message">
        <div className="user-message-details">
          <img src={userLogo} alt="User Logo" className="message-icon" />
          <span className="title">User</span>
        </div>
        <p className="response client-question">{item.user_input}</p>
      </div>
    </div>
    <div className="knightingale-message-container">
      <div className="knightingale-message">
        <div className="knightingale-message-details">
          <div className="aspira-bot-profile">
            <img
              src={user}
              alt="Knightingale Logo"
              className="message-icon"
            />
            <div className="title">Aspi</div>
          </div>
        </div>

        <div className="response bot-answer">
          <div className="play-button-container">
            {isVoiceSynthesisInProgress && (
              <div className="play_loading_wrapper">
                <div className="play_loading_container">
                  <PlayLoading />
                </div>
              </div>
            )}
            {!isVoiceSynthesisInProgress && // Check if audio synthesis is not in progress
              (currentlyPlayingIndex === index ? (
                <button
                  type="button"
                  onClick={handleStopButtonClick}
                  className={`stop-button ${item.agent_response ? "" : "hidden"
                    }`}
                >
                  <img
                    src={stop}
                    height={20}
                    width={20}
                    alt="Knightingale said"
                    className="stop-icon"
                  />
                </button>
              ) : (
                <button
                  type="button"
                  onClick={() => playAudio(item.agent_response, index)}
                  className={`play-audio-button ${item.agent_response ? "" : "hidden"
                    }`}
                >
                  {currentlyPlayingIndex !== index ? (
                    <img
                      src={play}
                      height={30}
                      width={30}
                      alt="Knightingale says"
                      className="play-icon"
                    />
                  ) : null}
                </button>
              ))}
          </div>
          <div>
            {item.agent_response}
          </div>
        </div>
      </div>
    </div>
  </li>
))}

                </ul>
              </div>

            </div>
          </div>
          <div className="share_footer">
            <div className="share_footer_left">

            </div>
            <div className="share_footer_btn_container">
              <div className="share_footer_btn">
                <a href="https://ask.aspirationsilab.com/">Chat with Aspi!</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareComponent;

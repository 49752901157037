import React, { useEffect, useState } from "react";
import "./Personalization.css";
import "../Layout/MainLayout.css";
import "../Start/Start.css";
import Navbar from "../Navbar/Navbar";

function Personalization(props) {
  const [formData, setFormData] = useState({
    userId: "654dff8f8d0359a1d1550070",
    nickname: "",
    birthday: "",
    biologicalSex: "",
    height: "",
    weight: "",
    medicalConditions: "",
    currentMedications: "",
    knownAllergies: "",
    aboutMe: "",
    healthSummary: "",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        `https://ask.aspirations.solutions:5000/update-user-data/${formData.userId}`,
        {
          method: "PUT", // Use PUT method for updates
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        // Optionally, you can perform additional actions upon successful update
      } else {
        console.error("Failed to update user data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };



  return (
    <>
      <div className="">
        <div className="main-three-colum-layout-container">
          <div className="main-three-colum-layout start-main-three-colum-layout">
            <div className="right-column ">
              <Navbar />
            </div>
          </div>
        </div>
      </div>
      <div className="personlization-form-wrapper-outer">
        <div className="personlization-form-wrapper">
          <div className="personalization-form-inner-wrapper">
            <div className="personalization-form-container">
              <div className="personalization-form-header">
                <h3>Personalization</h3>
              </div>
              <form onSubmit={handleUpdate} className="personalization-form">
                <div className="profile-label-input-wrapper">

                  <label htmlFor="nickname" className="form-label">
                    Nickname
                  </label>
                  <input
                    type="text"
                    id="nickname"
                    name="nickname"
                    value={formData.nickname}
                    onChange={handleInputChange}
                    className="form-input"
                    required
                  />
                </div>
                <div className="profile-label-input-wrapper">

                  <label htmlFor="birthday" className="form-label">
                    Birthday
                  </label>
                  <input
                    type="date"
                    id="birthday"
                    name="birthday"
                    value={formData.birthday}
                    onChange={handleInputChange}
                    className="form-input form-input-bdy"
                    required
                  />
                </div>
                <div className="profile-label-input-wrapper">

                  <label htmlFor="biological-sex" className="form-label">
                    Biological Sex
                  </label>
                  <select
                    id="biological-sex"
                    name="biologicalSex"
                    value={formData.biologicalSex}
                    onChange={handleInputChange}
                    className="form-input"
                    required
                  >
                    <option value="" className=" form-input-bs">----   Please Choose An Option   ----</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                </div>
                <div className="profile-label-input-wrapper">

                  <label htmlFor="height" className="form-label">
                    Height
                  </label>
                  <input
                    type="number"
                    id="height"
                    name="height"
                    value={formData.height}
                    onChange={handleInputChange}
                    className="form-input"
                    required
                  />
                </div>
                <div className="profile-label-input-wrapper">

                  <label htmlFor="weight" className="form-label">
                    Weight
                  </label>
                  <input
                    type="number"
                    id="weight"
                    name="weight"
                    value={formData.weight}
                    onChange={handleInputChange}
                    className="form-input"
                    required
                  />
                </div>
                <div className="profile-label-input-wrapper">

                  <label htmlFor="medical-conditions" className="form-label">
                    Medical Conditions
                  </label>
                  <textarea
                    id="medical-conditions"
                    name="medicalConditions"
                    value={formData.medicalConditions}
                    onChange={handleInputChange}
                    className="form-input"
                    required
                  ></textarea>
                </div>
                <div className="profile-label-input-wrapper">

                  <label htmlFor="current-medications" className="form-label">
                    Current Medications
                  </label>
                  <textarea
                    id="current-medications"
                    name="currentMedications"
                    value={formData.currentMedications}
                    onChange={handleInputChange}
                    className="form-input"
                    required
                  ></textarea>
                </div>
                <div className="profile-label-input-wrapper">

                  <label htmlFor="known-allergies" className="form-label">
                    Known Allergies
                  </label>
                  <textarea
                    id="known-allergies"
                    name="knownAllergies"
                    value={formData.knownAllergies}
                    onChange={handleInputChange}
                    className="form-input"
                    required
                  ></textarea>
                </div>
                <div className="profile-label-input-wrapper">

                  <label htmlFor="about-me" className="form-label">
                    About Me
                  </label>
                  <textarea
                    id="about-me"
                    name="aboutMe"
                    value={formData.aboutMe}
                    onChange={handleInputChange}
                    className="form-input"
                    required
                  ></textarea>
                </div>
                <div className="profile-label-input-wrapper">

                  <label htmlFor="health-summary" className="form-label">
                    Health Summary
                  </label>
                  <textarea
                    id="health-summary"
                    name="healthSummary"
                    maxLength="240"
                    value={formData.healthSummary}
                    onChange={handleInputChange}
                    className="form-input"
                    required
                  ></textarea>
                </div>
                <div className="profile-label-input-wrapper profile-label-input-wrapper-button">

                  <button type="submit" className="form-button">
                    Update
                  </button>
                </div>
              </form>
            </div><div className="footer-set"></div>
          </div>
        </div>


      </div>
    </>
  );
}

export default Personalization;

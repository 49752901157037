import React, { useState } from 'react';
import './PersonalityTest.css';
import rating1 from "../../img/rating/rating1.png";
import rating2 from "../../img/rating/rating2.png";
import rating3 from "../../img/rating/rating3.png";
import rating4 from "../../img/rating/rating4.png";
import rating5 from "../../img/rating/rating5.png";


const PersonalityTest = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const chatSession = localStorage.getItem("chatSession");
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [reportData, setReportData] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [answers, setAnswers] = useState({
    name: '',
    quickPick: '',
    learningStyle: '',
    decisionMaking: '',
    lifeApproach: '',
    ratings: {
      statement1: '',
      statement2: '',
      statement3: '',
      statement4: '',
      statement5: '',
      statement6: '',
      statement7: '',
      statement8: '',
    },
    sports: [],
    traits: [],
    traits2: [], // Add traits to the answers state
    personalityDescription: '',
    personalStrenth: '',
    developarealStrenth: '',
    feelMostEnlightened: '',
  });
  const [completedSteps, setCompletedSteps] = useState(0);

  // Count completed steps based on the answers
  const countCompletedSteps = () => {
    let count = 0;
    if (answers.name) count++;
    if (answers.quickPick) count++;
    if (answers.learningStyle) count++;
    if (answers.decisionMaking) count++;
    if (answers.lifeApproach) count++;
    if (answers.ratings.statement1) count++;
    if (answers.ratings.statement2) count++;
    if (answers.ratings.statement3) count++;
    if (answers.ratings.statement4) count++;
    if (answers.ratings.statement5) count++;
    if (answers.ratings.statement6) count++;
    if (answers.ratings.statement7) count++;
    if (answers.ratings.statement8) count++;
    if (answers.sports.length > 0) count++;
    if (answers.traits.length > 0) count++; // Include traits in count
    if (answers.traits2.length > 0) count++;
    if (answers.personalityDescription) count++;
    if (answers.personalStrenth) count++;
    if (answers.developarealStrenth) count++;
    if (answers.feelMostEnlightened) count++;


    setCompletedSteps(count);
  };

  // Handler for moving to the next step
  const handleNext = () => {
    const showError = (message) => {
      const errorElement = document.createElement('div');
      errorElement.className = 'error-message';
      errorElement.textContent = message;
      document.body.appendChild(errorElement);
      setTimeout(() => {
        document.body.removeChild(errorElement);
      }, 3000); // Remove the message after 3 seconds
    };

    if (currentStep === 1 && !answers.name) {
      showError("Please enter your name to proceed.");
      return;
    }
    if (currentStep === 2 && !answers.quickPick) {
      showError("Please choose an option to proceed.");
      return;
    }
    if (currentStep === 3 && !answers.learningStyle) {
      showError("Please select how you prefer to learn to proceed.");
      return;
    }
    if (currentStep === 4 && !answers.decisionMaking) {
      showError("Please select how you make important decisions to proceed.");
      return;
    }
    if (currentStep === 5 && !answers.lifeApproach) {
      showError("Please select your general approach to life to proceed.");
      return;
    }
    if (currentStep >= 6 && currentStep <= 13) {
      const statementIndex = currentStep - 5; // Map currentStep to statement number
      if (!answers.ratings[`statement${statementIndex}`]) {
        showError("Please rate the statement to proceed.");
        return;
      }
    }

    if (currentStep === 14 && answers.traits.length === 0) {
      showError("Please select at least one trait to proceed.");
      return;
    }

    if (currentStep === 15 && answers.traits2.length === 0) {
      showError("Please select at least one trait to proceed.");
      return;
    }

    if (currentStep === 16 && !answers.personalityDescription) {
      showError("Please enter your personality description to proceed.");
      return;
    }

    if (currentStep === 17 && !answers.personalStrenth) {
      showError("Please enter your personal strength to proceed.");
      return;
    }

    if (currentStep === 18 && !answers.developarealStrenth) {
      showError("Please enter your developmental strength to proceed.");
      return;
    }

    if (currentStep === 19 && !answers.feelMostEnlightened) {
      showError("Please enter what makes you feel most enlightened to proceed.");
      return;
    }

    setCurrentStep(currentStep + 1);
    countCompletedSteps();
  };

  // Handler for moving to the previous step
  const handlePrevious = () => {
    setCurrentStep(currentStep - 1);
    countCompletedSteps(); // Ensure progress bar updates when going back
  };

  // Handler for updating text inputs
  const handleInputChange = (step, value) => {
    setAnswers(prevAnswers => {
      const newAnswers = {
        ...prevAnswers,
        [step]: value,
      };
      countCompletedSteps(); // Update progress bar on change
      return newAnswers;
    });
  };

  // Handler for updating radio inputs
  const handleRadioChange = (step, value) => {
    // Update the state with the new value and also update the progress bar
    setAnswers(prevAnswers => {
      const updatedAnswers = {
        ...prevAnswers,
        [step]: value,
      };
      countCompletedSteps(); // Update progress bar on change
      return updatedAnswers;
    });

    // Manage label classes
    const labels = document.querySelectorAll('.radio-label');
    labels.forEach(label => label.classList.remove('checked'));
    const checkedLabel = document.querySelector(`input[name="${step}"][value="${value}"]`).closest('.radio-label');
    if (checkedLabel) {
      checkedLabel.classList.add('checked');
    }
  };


  // Handler for updating checkbox inputs
  const handleCheckboxChange = (trait) => {
    setAnswers(prevAnswers => {
      const newTraits = prevAnswers.traits.includes(trait)
        ? prevAnswers.traits.filter(item => item !== trait)
        : [...prevAnswers.traits, trait];
      const newAnswers = {
        ...prevAnswers,
        traits: newTraits,
      };
      countCompletedSteps(); // Update progress bar on change
      return newAnswers;
    });
  };

  const handleCheckboxChange2 = (trait2) => {
    setAnswers(prevAnswers2 => {
      const newTraits2 = prevAnswers2.traits2.includes(trait2)
        ? prevAnswers2.traits2.filter(item => item !== trait2)
        : [...prevAnswers2.traits2, trait2];
      const newAnswers2 = {
        ...prevAnswers2,
        traits2: newTraits2,
      };
      countCompletedSteps(); // Update progress bar on change
      return newAnswers2;
    });
  };
  // Handler for updating rating inputs
  const handleRatingChange = (statement, value) => {
    setAnswers(prevAnswers => {
      const newAnswers = {
        ...prevAnswers,
        ratings: { ...prevAnswers.ratings, [statement]: value },
      };
      countCompletedSteps(); // Update progress bar on change
      return newAnswers;
    });
  };

  // Handler for finishing the wizard
  const handleFinish = async (event) => {
    event.preventDefault();
    // Prepare questions and answers
    const ratingScale = {
      1: "Strongly Disagree",
      2: "Disagree",
      3: "Neutral",
      4: "Agree",
      5: "Strongly Agree"
    };

    // Prepare questions and answers
    const content = `
  1. What's your name? ${answers.name}
  2. How do you feel most refreshed and energized? ${answers.quickPick}
  3. How do you prefer to learn and understand new things? ${answers.learningStyle}
  4. How do you make important decisions? ${answers.decisionMaking}
  5. What is your general approach to life? ${answers.lifeApproach}
  6. I love coming up with new ideas and imagining what could be. ${ratingScale[answers.ratings.statement1]}
  7. I enjoy doing things with my hands and seeing tangible results. ${ratingScale[answers.ratings.statement2]}
  8. I like to think about things from different angles before making a decision. ${ratingScale[answers.ratings.statement3]}
  9. I care deeply about how other people feel. ${ratingScale[answers.ratings.statement4]}
  10. I get excited by busy and ever-changing situations. ${ratingScale[answers.ratings.statement5]}
  11. I like having a familiar routine and knowing what to expect. ${ratingScale[answers.ratings.statement6]}
  12. I like having lively discussions and debates about different ideas. ${ratingScale[answers.ratings.statement7]}
  13. I'm comfortable sharing my feelings and connecting with others emotionally. ${ratingScale[answers.ratings.statement8]}
  14. Words that describe me: ${answers.traits.join(', ')}
  15. In a group, I tend to: ${answers.traits2.join(', ')}
  16. Describe your personality: ${answers.personalityDescription}
  17. What is your personal strength? ${answers.personalStrenth}
  18. What is your developmental strength? ${answers.developarealStrenth}
  19. What makes you feel most enlightened? ${answers.feelMostEnlightened}
  `;

    const payload = {
      conversations: [
        {
          role: "user",
          content: content,
        }
      ],
      chatSession: chatSession,
    };

    setIsPopupVisible(true);


    try {
      const response = await fetch('https://dashboard.knightingale.ai/ai/run/7ac54f14-d1b8-4529-bf25-58c77bf5435e', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
      console.log('ss' + response.completion)
      if (response.ok) {

        const data = await response.json(); // Parse the JSON from the response
        console.log(data.completion);
        setReportData(data.completion);

        setAnswers({
          name: '',
          quickPick: '',
          learningStyle: '',
          decisionMaking: '',
          lifeApproach: '',
          ratings: {
            statement1: '',
            statement2: '',
            statement3: '',
            statement4: '',
            statement5: '',
            statement6: '',
            statement7: '',
            statement8: '',
          },
          sports: [],
          traits: [],
          traits2: [],
          personalityDescription: '',
          personalStrenth: '',
          developarealStrenth: '',
          feelMostEnlightened: '',
        });

        setIsSubmitted(true); // Show the submitted message

      } else {
        console.error('Error submitting the form:', response.statusText);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      // setIsPopupVisible(false); // Hide the popup
    }


    console.log(content);

  };


  // Render progress bar
  const renderProgressBar = () => (
    <div className="progress-bar-container">
      <progress value={completedSteps} max="19" className="progress-bar" />
      <p className="progress-text">
        {completedSteps} of 19 questions answered
      </p>
    </div>
  );


  // Render rating step with buttons
  const renderRatingStep = (statement, step) => {
    // Array of images corresponding to each rating
    const ratingImages = [rating5, rating4, rating3, rating2, rating1];
    // Array of text labels corresponding to each rating
    const ratingTexts = ["Strongly Disagree", "Disagree", "Neutral", "Agree", "Strongly Agree"]; // Adjust as needed

    return (
      <div className="wizard-section">
        <h3 className="section-title">Step {step}: Rate the Statement</h3>
        <p className="question-text">Rate the following statement from 1 to 5:</p>
        <p className="statement-text">{statement}</p>
        <div className="rating-buttons">
          {[1, 2, 3, 4, 5].map((rating, index) => (
            <button
              key={rating}
              className={`rating-button ${answers.ratings[`statement${step}`] === rating ? 'selected' : ''}`}
              onClick={() => handleRatingChange(`statement${step}`, rating)}
            >
              <img src={ratingImages[index]} alt={`Rating ${rating}`} />
              <span className="rating-text">{ratingTexts[index]}</span>
            </button>
          ))}
        </div>

      </div>
    );
  };


  // Render traits step
  const renderTraitsStep = () => (
    <div className="wizard-section">
      <h3 className="section-title">Part 3: Pick Your Traits</h3>
      <div>

        <p className="question-text">Choose all that apply to you:</p>
        <div className="checkbox-group">
          {[
            "Analytical",
            "Creative",
            "Empathetic",
            "Organized",
            "Spontaneous",
            "Practical",
            "Visionary",
            "Adaptable",
          ].map(trait => (
            <label key={trait}>
              <input
                type="checkbox"
                checked={answers.traits.includes(trait)}
                onChange={() => handleCheckboxChange(trait)}
              />
              {trait}
            </label>
          ))}
        </div>
        <div></div>

      </div>

    </div>
  );

  const renderTraitsStep2 = () => (
    <div className="wizard-section">
      <h3 className="section-title">Part 3: Pick Your Traits</h3>
      <div>
        <p className="question-text">In a group, I tend to:</p>
        <div className="checkbox-group">
          {[
            "Take the lead and get things started.",
            "Offer support and encouragement to others",
            "Share logical and objective viewpoints.",
            "Come up with new and creative ideas.",
            "Make sure everyone feels included and understood.",
            "Focus on getting things done and paying attention to details.",
          ].map(trait2 => (
            <label key={trait2}>
              <input
                type="checkbox"
                checked={answers.traits2.includes(trait2)}
                onChange={() => handleCheckboxChange2(trait2)}
              />
              {trait2}
            </label>
          ))}
        </div>

      </div>
      <div></div>
    </div>
  );



  const handleClose = () => {
    setIsSubmitted(true);
    window.location.href = '/'
  };

  // const handlePrint = () => {
  //   window.print();
  // };



  const handlePrint = () => {
    const printWindow = window.open('', '', 'height=600,width=800');
    printWindow.document.write('<html><head><title>Print</title></head><body>');
    printWindow.document.write('<pre>' + reportData + '</pre>');
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
  };
  return (
    <div className="wizard-container">
      <p className="progress-text">
        {completedSteps} of 19 questions answered
      </p>
      {currentStep === 1 && (
        <div className="wizard-section">
          <h3 className="section-title">Let's Discover Your Unique Personality!</h3>
          <div className='personal-description'>

            <p className="greeting-text">First things first, what's your name?</p>
            <input
              type="text"
              placeholder="Enter your first name"
              value={answers.name}
              onChange={(e) => handleInputChange('name', e.target.value)}
              className="input-field"
            />
          </div>
          <br />
          {answers.name && (
            <p className="greeting-text">
              Hi {answers.name}, excited to embark on this personality journey with you! Let's get started.
            </p>
          )}

        </div>
      )}

      {currentStep === 2 && (
        <div className="wizard-section">
          <h3 className="section-title">Part 1: Quick Picks</h3>
          <p className="question-text">Choose the option that best describes you:</p>
          <p className="question-text question-text2">How do you feel most refreshed and energized?</p>
          <div className="radio-group part1-radio-group">
            <label className={`radio-label ${answers.quickPick === "Spending time with friends and being out and about." ? 'checked' : ''}`}>
              <input
                type="radio"
                name="quickPick"
                value="Spending time with friends and being out and about."
                checked={answers.quickPick === "Spending time with friends and being out and about."}
                onChange={(e) => handleRadioChange('quickPick', e.target.value)}
              />
              <img
                src={rating1}
                alt="Spending time with friends and being out and about"
                onClick={() => handleRadioChange('quickPick', 'Spending time with friends and being out and about.')}
                className="radio-image"
              />
              <p className='part1text'>Spending time with friends and being out and about.</p>
            </label>
            <label className={`radio-label ${answers.quickPick === "Having quiet time alone or deep conversations with a few close friends." ? 'checked' : ''}`}>
              <input
                type="radio"
                name="quickPick"
                value="Having quiet time alone or deep conversations with a few close friends."
                checked={answers.quickPick === "Having quiet time alone or deep conversations with a few close friends."}
                onChange={(e) => handleRadioChange('quickPick', e.target.value)}
              />
              <img
                src={rating2}
                alt="Having quiet time alone or deep conversations with a few close friends."
                onClick={() => handleRadioChange('quickPick', 'Having quiet time alone or deep conversations with a few close friends.')}
                className="radio-image"
              />
              <p className='part1text'>Having quiet time alone or deep conversations with a few close friends.</p>
            </label>
          </div>
        </div>
      )}

      {currentStep === 3 && (
        <div className="wizard-section">
          <h3 className="section-title">Part 1: Quick Picks</h3>
          <p className="question-text">Choose the option that best describes you:</p>
          <p className="question-text question-text2">How do you prefer to learn and understand new things?</p>
          <div className="radio-group part1-radio-group">
            <label className={`radio-label ${answers.learningStyle === "Focusing on practical details and real-world examples." ? 'checked' : ''}`}>
              <input
                type="radio"
                name="learningStyle"
                value="Focusing on practical details and real-world examples."
                checked={answers.learningStyle === "Focusing on practical details and real-world examples."}
                onChange={(e) => handleRadioChange('learningStyle', e.target.value)}
              />
              <img
                src={rating3}
                alt="Focusing on practical details and real-world examples"
                onClick={() => handleRadioChange('learningStyle', 'Focusing on practical details and real-world examples.')}
                className="radio-image"
              />
              <p className='part1text'>Focusing on practical details and real-world examples.</p>
            </label>
            <label className={`radio-label ${answers.learningStyle === "Exploring big ideas, possibilities, and connecting the dots." ? 'checked' : ''}`}>
              <input
                type="radio"
                name="learningStyle"
                value="Exploring big ideas, possibilities, and connecting the dots."
                checked={answers.learningStyle === "Exploring big ideas, possibilities, and connecting the dots."}
                onChange={(e) => handleRadioChange('learningStyle', e.target.value)}
              />
              <img
                src={rating3}
                alt="Exploring big ideas, possibilities, and connecting the dots"
                onClick={() => handleRadioChange('learningStyle', 'Exploring big ideas, possibilities, and connecting the dots.')}
                className="radio-image"
              />
              <p className='part1text'>Exploring big ideas, possibilities, and connecting the dots.</p>
            </label>
          </div>
        </div>
      )}

      {currentStep === 4 && (
        <div className="wizard-section">
          <h3 className="section-title">Part 1: Quick Picks</h3>
          <p className="question-text">Choose the option that best describes you:</p>
          <p className="question-text question-text2">How do you make important decisions?</p>
          <div className="radio-group part1-radio-group">
            <label className={`radio-label ${answers.decisionMaking === "Using logic, facts, and careful analysis." ? 'checked' : ''}`}>
              <input
                type="radio"
                name="decisionMaking"
                value="Using logic, facts, and careful analysis."
                checked={answers.decisionMaking === "Using logic, facts, and careful analysis."}
                onChange={(e) => handleRadioChange('decisionMaking', e.target.value)}
              />
              <img
                src={rating4}
                alt="Using logic, facts, and careful analysis"
                onClick={() => handleRadioChange('decisionMaking', 'Using logic, facts, and careful analysis.')}
                className="radio-image"
              />
              <p className='part1text'>Using logic, facts, and careful analysis.</p>
            </label>
            <label className={`radio-label ${answers.decisionMaking === "Considering my values, feelings, and how my choices impact others." ? 'checked' : ''}`}>
              <input
                type="radio"
                name="decisionMaking"
                value="Considering my values, feelings, and how my choices impact others."
                checked={answers.decisionMaking === "Considering my values, feelings, and how my choices impact others."}
                onChange={(e) => handleRadioChange('decisionMaking', e.target.value)}
              />
              <img
                src={rating5}
                alt="Considering my values, feelings, and how my choices impact others"
                onClick={() => handleRadioChange('decisionMaking', 'Considering my values, feelings, and how my choices impact others.')}
                className="radio-image"
              />
              <p className='part1text'>Considering my values, feelings, and how my choices impact others.</p>
            </label>
          </div>
        </div>
      )}

      {currentStep === 5 && (
        <div className="wizard-section">
          <h3 className="section-title">Part 1: Quick Picks</h3>
          <p className="question-text">Choose the option that best describes you:</p>
          <p className="question-text question-text2">What's your general approach to life and everyday tasks?</p>
          <div className="radio-group part1-radio-group">
            <label className={`radio-label ${answers.lifeApproach === "I like having a clear plan and sticking to a schedule." ? 'checked' : ''}`}>
              <input
                type="radio"
                name="lifeApproach"
                value="I like having a clear plan and sticking to a schedule."
                checked={answers.lifeApproach === "I like having a clear plan and sticking to a schedule."}
                onChange={(e) => handleRadioChange('lifeApproach', e.target.value)}
              />
              <img
                src={rating1}
                alt="I like having a clear plan and sticking to a schedule"
                onClick={() => handleRadioChange('lifeApproach', 'I like having a clear plan and sticking to a schedule.')}
                className="radio-image"
              />
              <p className='part1text'>I like having a clear plan and sticking to a schedule.</p>
            </label>
            <label className={`radio-label ${answers.lifeApproach === "I prefer going with the flow and being open to changes." ? 'checked' : ''}`}>
              <input
                type="radio"
                name="lifeApproach"
                value="I prefer going with the flow and being open to changes."
                checked={answers.lifeApproach === "I prefer going with the flow and being open to changes."}
                onChange={(e) => handleRadioChange('lifeApproach', e.target.value)}
              />
              <img
                src={rating2}
                alt="I prefer going with the flow and being open to changes"
                onClick={() => handleRadioChange('lifeApproach', 'I prefer going with the flow and being open to changes.')}
                className="radio-image"
              />
              <p className='part1text'>I prefer going with the flow and being open to changes.</p>
            </label>
          </div>
        </div>
      )}


      {currentStep === 6 && renderRatingStep("I love coming up with new ideas and imagining what could be.", 1)}

      {currentStep === 7 && renderRatingStep("I enjoy doing things with my hands and seeing tangible results.", 2)}

      {currentStep === 8 && renderRatingStep("I like to think about things from different angles before making a decision.", 3)}

      {currentStep === 9 && renderRatingStep("I care deeply about how other people feel.", 4)}

      {currentStep === 10 && renderRatingStep("I get excited by busy and ever-changing situations.", 5)}

      {currentStep === 11 && renderRatingStep("I like having a familiar routine and knowing what to expect.", 6)}

      {currentStep === 12 && renderRatingStep("I like having lively discussions and debates about different ideas.", 7)}

      {currentStep === 13 && renderRatingStep("I'm comfortable sharing my feelings and connecting with others emotionally.", 8)}

      {currentStep === 14 && renderTraitsStep()}

      {currentStep === 15 && renderTraitsStep2()}

      {currentStep === 16 && (
        <div className="wizard-section">
          <h3 className="section-title">Just for You, {answers.name}</h3>
          <div className='personal-description'>

            <p className="question-text">In 3-5 words, describe your personality:</p>
            <textarea
              type="text"
              name="personalityDescription"
              value={answers.personalityDescription || ''}
              onChange={(e) => handleInputChange('personalityDescription', e.target.value)}
              className="text-input"
              placeholder="Enter 3-5 words"
            />
          </div>
          <div></div>

        </div>
      )}

      {currentStep === 17 && (
        <div className="wizard-section">
          <h3 className="section-title">Just for You, {answers.name}</h3>
          <div className='personal-description'>


            <p className="question-text">Your top 2-3 strengths:</p>
            <textarea
              type="text"
              name="personalStrenth"
              value={answers.personalStrenth || ''}
              onChange={(e) => handleInputChange('personalStrenth', e.target.value)}
              className="text-input"
              placeholder="Enter 3-5 words"
            />
          </div>
          <div></div>

        </div>
      )}

      {currentStep === 18 && (
        <div className="wizard-section">
          <h3 className="section-title">Just for You, {answers.name}</h3>
          <div className='personal-description'>


            <p className="question-text">1-2 areas you'd like to develop</p>
            <textarea
              type="text"
              name="developarealStrenth"
              value={answers.developarealStrenth || ''}
              onChange={(e) => handleInputChange('developarealStrenth', e.target.value)}
              className="text-input"
              placeholder="1-2 areas you'd like to develop"
            />
          </div>
          <div></div>

        </div>
      )}
      {currentStep === 19 && (
        <div className="wizard-section">
          <h3 className="section-title">Just for You, {answers.name}</h3>
          <div className='personal-description'>

            <p className="question-text">In one sentence, what makes you feel most alive and fulfilled?</p>
            <textarea
              type="text"
              name="feelMostEnlightened"
              value={answers.feelMostEnlightened || ''}
              onChange={(e) => handleInputChange('feelMostEnlightened', e.target.value)}
              className="text-input"
              placeholder="In one sentence, what makes you feel most alive and fulfilled?"
            />
          </div>
          <div></div>
        </div>
      )}





      <div className="button-group">
        {renderProgressBar()}

        <div className='prev-next-container'>

          {currentStep > 1 && (<button onClick={handlePrevious} className="button previous-button">Previous</button>)}
          {currentStep < 20 && (<button onClick={handleNext} className="button next-button">Next</button>)}
        </div>
        {currentStep === 20 && (
          <div className="wizard-section">
            <div className="wizard-section-container">

              <h3 className="section-title"> Thanks for sharing, {answers.name}</h3>
              <p className="completion-text"> Can't wait to reveal your unique personality profile!</p>
              <button onClick={handleFinish} className="button finish-button">Finish</button>
            </div>
          </div>
        )}
      </div>
      {isPopupVisible && (
        <div className='overlay'>
          {isSubmitted ? (
            <div className='successMessage'>

              <div className='successText'>Thank you! Your form has been submitted successfully.</div>
              <div className='printButtons'>
                <button className='okButton' onClick={handleClose}>OK</button>
                <button className='okButton' onClick={handlePrint}>Print Report</button>
              </div>

            </div>
          ) : (
            <div className='popupContent'>
              <div className='circle-loader'></div>
              <div className='popupText'>Submitting...</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PersonalityTest;






















